import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GenreDetails from "../components/GenreDetails";
import Stack from "@mui/material/Stack";
import PageTitle from "../components/PageTitle";

export default function GenrePage() {
  const { genre } = useParams();
  const [ genreDecoded, setGenre ] = useState(null);

  useEffect(() => {
    const genreParamDecoded = decodeURIComponent(genre);
    setGenre(genreParamDecoded);
  }, [genre]);

  return (<>
    {genreDecoded &&
      <Stack
        align="center"
        sx={{
          justifyContent: "center",
          alignItems: "center"
        }}>
        <PageTitle title={genreDecoded} description="releases" />
        <GenreDetails genre={genreDecoded} />
      </Stack>
    }
  </>);
}
