import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { fonts, colors } from "../theme";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GenreDetails from "./GenreDetails";
import Button from "@mui/material/Button";
import InfoIcon from '@mui/icons-material/Info';
import { GenreDataContext } from "./GenreDataContext";
import { getArtistAlbumURI, getGenreURI, getGenreAIURL, defaultErrorMessage, makeRequest } from "../global";
import Fade from '@mui/material/Fade';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import history from "history/browser";
import PageTitle from "./PageTitle";
import { UserContext } from "./UserContext";

export default function Search(props) {
  const navigate = useNavigate();
  const genreContext = useContext(GenreDataContext);
  const [openAnalysisTip, setOpenAnalysisTip] = useState(false);
  const [showTopGenres, setShowTopGenres] = useState(true);
  const [searchingSubnote, setSearchingSubnote] = useState("");
  const [genre, setGenre] = useState(null);
  const [genreHref, setGenreHref] = useState(null);
  // setGoDeeper after setSearchResults to prevent multiple executions of getGenre upon initial execution of this module.
  const [searchResults, setSearchResults] = useState(null);
  const [goDeeper, setGoDeeper] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [genresAndLinks, setGenresAndLinks] = useState(null);
  const [topGenresAndLinks, setTopGenresAndLinks] = useState(null);
  const [fetchingGenres, setFetchingGenres] = useState(false);
  const userContext = useContext(UserContext);

  const getArtistTitleHref = () => {
    if (searchResults.album_name) {
      return getArtistAlbumURI(searchResults.artist_name, searchResults.artist_id);
    } else {
      return null;
    }
  }

  const updateGenre = (genre) => {
    setGenre(genre);
    setGenreHref(getGenreURI(genre));
  }

  const getArtistGenres = (event) => {
    navigate(getArtistAlbumURI(searchResults.artist_name, searchResults.artist_id));
  }

  // Search requests always have to pass artist/album names, but IDs are optional since
  // we'll use them from search results.
  const defaultSearchResults = useMemo(() => {
    return {
      artist_name: props.artistName,
      album_name: props.albumName,
      artist_id: props.artistID,
      album_id: props.albumID,
      analysis: {
        level: 0
      },
      genres: [],
      top_genres: [],
      unvalidated_genres: [],
    }
  }, [props]);

  useEffect(() => {
    if (openAnalysisTip) {
      document.getElementById('analysis-tip').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [openAnalysisTip]);

  useEffect(() => {
    if (genre) {
      document.getElementById('genre-playlist').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [genre]);

  // Keep trying to get genres while goDeeper is true and searchResults are updating.
  useEffect(() => {
    const getGenres = async () => {
      const getGenreSearchURL = `${getGenreAIURL}/search`;
      let artistIDSearchParam = "artist_id=";
      let artistNameSearchParam = "artist_name=";
      let albumIDSearchParam = "album_id=";
      let albumNameSearchParam = "album_name=";

      if (searchResults.artist_id) {
        artistIDSearchParam = `artist_id=${encodeURIComponent(searchResults.artist_id)}`;
      }

      if (searchResults.artist_name) {
        artistNameSearchParam = `artist_name=${encodeURIComponent(searchResults.artist_name)}`;
      }

      if (searchResults.album_id) {
        albumIDSearchParam = `album_id=${encodeURIComponent(searchResults.album_id)}`;
      }

      if (searchResults.album_name) {
        albumNameSearchParam = `album_name=${encodeURIComponent(searchResults.album_name)}`;
      }

      // TODO would be good to know when errors are happening for users with sending metrics/alerts, maybe not for 404s though?
      // Logic depends on searchResults.error, so we need to update that object with our own default
      // error message.
      const setDefaultErrorMessage = () => {
        const s = {...searchResults, error: defaultErrorMessage};
        setSearchResults(s);
      };

      let networkErrorRetries = 0;
      let retryLimit = 2;
      
      while (true) {
        let url = getGenreSearchURL + '?' + artistIDSearchParam + '&' + artistNameSearchParam + '&' + albumIDSearchParam + '&' + albumNameSearchParam

        try {
          let result;

          if (userContext.loggedIn) {
            result = await userContext.handleRequest(async () => {
              return await makeRequest(url, "GET", undefined, undefined, true);
            });
          } else {
            result = await makeRequest(url, "GET");
          }

          let searchTime = Date.now() / 1000 - startTime;

          if (searchTime > 30) {
            setSearchingSubnote("(This might take a minute... or two. Check back later for quicker results.)");
          } else if (searchTime > 10) {
            setSearchingSubnote("(This might take a minute.)");
          }

          if (!(result && result.data)) {
            throw result;
          }

          setSearchResults(result.data);
        } catch (error) {
          if (error) {
            if (error.response && error.response.data) {
              // don't replace searchResults with errors in case it replaces any good genre data we might already have.
              if (searchResults.genres.length === 0 && error.response.data.error) {
                setSearchResults(error.response.data);
              } else {
                setDefaultErrorMessage();
              }
            // Retry for unexpected network errors.
            } else if (networkErrorRetries < retryLimit) {
              await new Promise(r => setTimeout(r, 1000 * networkErrorRetries));
              networkErrorRetries++;
              continue
            } else {
              setDefaultErrorMessage();
            }
          // If the user has a poor connection, the request may not even be sent.
          } else if (networkErrorRetries < retryLimit) {
            await new Promise(r => setTimeout(r, 1000 * networkErrorRetries));
            networkErrorRetries++;
            continue
          } else {
            setDefaultErrorMessage();
          }
        }
        break;
      }
    };

    const getGenresWithLinks = async (genres) => {
      let gwl = []
      for (let i = 0; i < genres.length; i++) {
        const genre = genres[i].replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        const genreLowercased = genre.toLowerCase();
        const defaultGenreButtonStyle = {
          fontWeight: "bold",
          fontFamily: fonts.boldGenreFont,
          margin: "5px",
          backgroundColor: colors.black
        };

        if (genreLowercased in genreContext.genresAndIds) {
          if (searchResults.unvalidated_genres.includes(genreLowercased)) {
            gwl.push(
              <Fade key={i} in={true}>
                <Stack>
                  <Button onClick={(e) => {updateGenre(genre)}} sx={{...defaultGenreButtonStyle, color: colors.pink, opacity:0.75}} variant="outlined" color='primary'>
                    {genre} <WarningAmberIcon color="primary" fontSize="small" sx={{paddingLeft: '5px'}}/>
                  </Button>
                </Stack>
              </Fade>
            );
          } else {
            gwl.push(
              <Fade key={i} in={true}>
                <Stack>
                  <Button onClick={(e) => {updateGenre(genre)}} sx={{...defaultGenreButtonStyle, color: colors.pink}} variant="outlined" color='primary'>
            
                    {genre}
                  </Button>
                </Stack>
              </Fade>
            );
          }
        } else {
          if (searchResults.unvalidated_genres.includes(genreLowercased)) {
            gwl.push(
              <Fade key={i} in={true}>
                <Stack>
                  <Button sx={{...defaultGenreButtonStyle, opacity:0.75}} variant="outlined" color='primary'>
                    {genre} <WarningAmberIcon color="primary" fontSize="small" sx={{paddingLeft: '5px'}}/>
                  </Button>
                </Stack>
              </Fade>
            );
          } else {
            gwl.push(
              <Fade key={i} in={true}>
                <Stack>
                  <Button sx={{...defaultGenreButtonStyle}} variant="outlined" color='primary'>
                    {genre}
                  </Button>
                </Stack>
              </Fade>
            );
          }
        }
      }

      return gwl
    }

    if (searchResults === null) {
      setSearchResults(defaultSearchResults);
    } else {
      const artistAlbumURI = getArtistAlbumURI(searchResults.artist_name, searchResults.artist_id, searchResults.album_name, searchResults.album_id)

      if (window.location.pathname !== artistAlbumURI) {
        history.replace(artistAlbumURI);
      }

      if (searchResults.genres) {
        (async () => {
          setGenresAndLinks(await getGenresWithLinks(searchResults.genres));
          setTopGenresAndLinks(await getGenresWithLinks(searchResults.top_genres));
        })()
      }

      if (goDeeper) {
        if (startTime) {
          if (searchResults.analysis.exhausted || searchResults.error) {
            setGoDeeper(false);
          } else {
            (async () => {
              // Since this is an async function, we need a lock around it to prevent the effect from
              // firing off another one when it's already in progress, such as when the userContext changes.
              if (!fetchingGenres) {
                setFetchingGenres(true);

                // Search immediately initially, then wait a bit in
                // between each successive search depending on if we have any genres yet.
                if (searchResults !== defaultSearchResults) {
                  if (searchResults.genres.length === 0) {
                    await new Promise(r => setTimeout(r, 1000));
                  } else {
                    await new Promise(r => setTimeout(r, 5000));
                  }
                }

                await getGenres();
                setFetchingGenres(false);
              }
            })();
          }
        } else {
          setStartTime(Date.now() / 1000);
        }
      }
    }
  }, [props, userContext, goDeeper, searchResults, startTime, genreContext, genre, fetchingGenres, defaultSearchResults]);

  return (
    (<Stack>
      {searchResults &&
        <Stack
          sx={{
            alignItems: "center",
            textAlign: "center"
          }}>
          <PageTitle title={searchResults.artist_name} subtitle={searchResults.album_name} titleHref={getArtistTitleHref()} description="genres" preserveTitleCase preserveSubtitleCase/>

          {(searchResults.genres.length === 0 && goDeeper &&
            <Stack sx={{
              alignItems: "center"
            }}>
              <Typography id="searching-note" sx={{ fontStyle: "italic", marginBottom: "0" }}>
                Searching...
              </Typography>
              <Typography id="searching-subnote" sx={{ fontStyle: "italic", marginBottom: "5px" }}>
                {searchingSubnote}
              </Typography>
              <LinearProgress sx={{width: "200px"}} aria-labelledby="searchProgress"/>
            </Stack>) ||

            <Stack
              sx={{
                alignItems: "center",
                width: "100%"
              }}>
              <Stack
                id="genres-text"
                sx={{
                  alignItems: "center",
                  marginBottom: "0",
                  fontWeight: "bold",
                  textTransform: "uppercase"
                }}>
                {/* Try not to replace good genre data with errors. */}
                {
                  (searchResults.genres.length === 0 && !searchResults.error && <Typography variant="h5">no genres found!</Typography>)
                    || (showTopGenres && topGenresAndLinks)
                    || genresAndLinks
                }
              </Stack>

              {(searchResults.genres.join(',') !== searchResults.top_genres.join(',')) &&
                <Button
                  variant="string"
                  id="show-all-genres"
                  sx={{
                    textTransform: 'none',
                    color: "secondary.main",
                    fontWeight: "bold",
                  }}
                  onClick={() => setShowTopGenres(!showTopGenres)}
                >
                  {((showTopGenres && "Show All Genres") || ((searchResults.top_genres.length > 1 && "Show Top Genres") || "Show Top Genre"))}
                </Button>
              }

              {searchResults.genres.length !== 0 &&
                <Stack
                  id='analysis-tip'
                  sx={{
                    marginTop: "25px",
                  }}>
                  <Button variant="contained" color='primary' sx={{fontWeight:"bold", textTransform:"none"}} onClick={() => setOpenAnalysisTip(!openAnalysisTip)}>
                    Analysis Level:{" "}
                    {searchResults.analysis.level} 
                    <InfoIcon fontSize="small" sx={{marginLeft:"5px"}}/>
                  </Button>
                </Stack>
              }

              {(goDeeper &&
                <Stack sx={{
                  alignItems: "center"
                }}>
                  <Typography id="analysis-note" sx={{ fontStyle: "italic" }}>
                    Increasing analysis level...
                  </Typography>
                  <LinearProgress sx={{width: "200px", marginBottom: "10px"}} aria-labelledby="analysisProgress"/>
                </Stack>) ||
                (searchResults.analysis.exhausted && searchResults.genres.length !== 0 &&
                  <Stack>
                    <Typography sx={{ fontStyle: "italic" }}>
                      Max accuracy reached!
                    </Typography>
                  </Stack>
                )
              }

              {searchResults.genres.length !== 0 && openAnalysisTip &&
                <Stack sx={{ marginBottom: "0" }}>
                  <Typography>
                    The getGenre AI will more accurately report genres in their leading order of relevance as it discovers and validates them over time.
                    The higher the analysis level, the more accurate the results!
                  </Typography>
                  <Typography>
                    Genres marked with " <WarningAmberIcon color="primary" fontSize="small" sx={{padding: "2px", verticalAlign: "middle"}}/>" have yet to be validated as relevant.
                  </Typography>
                </Stack>
              }

              {searchResults.error && <Typography variant="h5" color='error'>{searchResults.error}</Typography>}

              {searchResults.album_name && searchResults.genres.length === 0 && (searchResults.artist_id || searchResults.artist_name) &&
                <Button
                  variant="contained"
                  sx={{ fontWeight: "bold" }}
                  onClick={getArtistGenres}
                >
                  GET ARTIST GENRES
                </Button>
              }

              {genre &&
                <Stack
                  id='genre-playlist'
                  sx={{
                    marginTop: "15px",
                    width: "100%",
                    alignItems: "center"
                  }}>
                  <Typography variant="h6" sx={{
                    marginBottom: "5px"
                  }} >
                    Playlist:{" "}
                    <Link
                      href={genreHref}
                      sx={{
                        fontWeight: "bold",
                        color: colors.pink,
                        fontFamily: fonts.boldGenreFont,
                      }}
                    >
                      {genre}
                    </Link>
                  </Typography>
                  <GenreDetails genre={genre} />
                </Stack>
              }
            </Stack>
          }

          <Typography sx={{fontWeight: 'bold', marginTop: "30px", marginBottom: "0" }}>
            Discover more music genres by{" "}
            <Link
              href="/spotify"
              target="_blank"
              rel='noreferrer noopener'
              color="secondary"
            >
              following us on Spotify
            </Link>
            !
          </Typography>
        </Stack>
      }
    </Stack>)
  );
}
