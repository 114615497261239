import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import AnonymousProBoldWoff2 from './fonts/AnonymousPro-Bold.woff2';
import SourceSansProRegularWoff2 from './fonts/SourceSansPro-Regular.woff2';
import SourceSansProLightWoff2 from './fonts/SourceSansPro-Light.woff2';
import SourceSansProSemiBoldWoff2 from './fonts/SourceSansPro-SemiBold.woff2';

export const colors = {
  pink: "#EC407A",
  green: "#7acc44",
  white: "#fff",
  black: "#000",
  gray: "#aaa",
  pinkishBlack: "#190E12",
  pageBackgroundColor: "rgb(25, 14, 18, .7)",
};

export const fonts = {
  logoFont: "Anonymous Pro Bold",
  lightFont: "Source Sans Pro Light",
  boldFont: "Source Sans Pro Semi-bold",
}

export const styles = {
  boldGenreFont: {
    color: colors.pink,
    fontFamily: fonts.boldFont
  }
}

export const LinkBehavior = forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink ref={ref} to={href} {...other} />
  );
});

let theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: colors.black,
    },
    primary: {
      main: colors.white,
    },
    secondary: {
      main: colors.green,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "Roboto"].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Anonymous Pro Bold';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('AnonymousPro-Bold'), url(${AnonymousProBoldWoff2}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Source Sans Pro Light';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('SourceSansPro-Light'), url(${SourceSansProLightWoff2}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('SourceSansPro-Regular'), url(${SourceSansProRegularWoff2}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Source Sans Pro Semi-bold';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('SourceSansPro-SemiBold'), url(${SourceSansProSemiBoldWoff2}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
        color: "secondary",
        underline: "hover",
      },
      styleOverrides: {
        root: {
          fontFamily: fonts.boldFont,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          fontFamily: fonts.boldFont,
          marginBottom: "5px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          marginBottom: "10px",
        },
        h4: {
          marginBottom: "15px",
        },
        h5: {
          marginBottom: "10px",
        }
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&&:hover::before": {
            borderColor: colors.pink,
          },
          "&&:before": {
            borderColor: colors.gray,
          },
          "&&:after": {
            borderColor: colors.pink,
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          input: {
            fontFamily: fonts.boldFont,
          }
        }
      }
    },
  },
});

theme = createTheme(theme,{
  palette: {
    tertiary: theme.palette.augmentColor({
      color: {
        main: colors.pink,
      },
      name: 'tertiary',
    }),
  },
});

theme = responsiveFontSizes(theme);

export default theme;
