import { Stack, Typography } from "@mui/material";
import { colors, fonts } from "../theme";
import { useState, useRef, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, useLocation } from "react-router-dom";
import { GenreDataContext } from "./GenreDataContext";
import { getGenreURI } from "../global";
import Link from '@mui/material/Link';

export default function Logo(props) {
  const location = useLocation();

  const genreContext = useContext(GenreDataContext);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  /** TODO this should be global for any other usage
   * Hook that alerts clicks outside of the passed ref
   * UPDATE: MUI has the logic we need: https://mui.com/base/api/click-away-listener/
   */
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleChange = (event, value) => {
    setOpen(false);
    navigate(getGenreURI(value));
  };

  return (
      <Typography
        variant={props.variant}
        component={props.component}
        ref={wrapperRef}
        sx={{
          fontFamily: fonts.logoFont,
          margin: "0",
        }}
      >
        <Stack
          direction="row"
          sx={{
            ...props.sx,
          }}
        >
          {location.pathname === "/" && "getGenre"}
          <Link
            onClick={() => {
              setOpen(!open);
            }}
            color="tertiary"
            underline="none"
            onMouseOver={() => {
              setOpen(true);
            }}
            sx={{
              fontFamily: fonts.logoFont
            }}
          >
            (
          </Link>
          {open === true && genreContext && (
            // TODO: bleeds off the page when small resolution.
            <Autocomplete
              open={open}
              disablePortal
              id="genre-search"
              options={genreContext.genres}
              onChange={handleChange}
              sx={{
                width: "100%",
                minWidth: "75px",
                maxWidth: "175px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="genre"
                />
              )}
              PaperComponent={(params) => (
                <Paper sx={{ minWidth: "175px" }} {...params} />
              )}
              ListboxProps={{
                sx: {
                  textAlign: "start",
                  borderStyle: "solid",
                  "&& .MuiAutocomplete-option": {
                    "&.Mui-focused": {
                      backgroundColor: colors.pink,
                    },
                  },
                },
              }}
            />
          )}
          <Link
            onClick={() => {
              setOpen(!open);
            }}
            color="tertiary"
            underline="none"
            onMouseOver={() => {
              setOpen(true);
            }}
            sx={{
              fontFamily: fonts.logoFont
            }}
          >
            )
          </Link>
          {location.pathname === "/" && "®" }
        </Stack>
      </Typography>
  );
}
