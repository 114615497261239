import Typography from "@mui/material/Typography";
import PageTitle from "../components/PageTitle";
import Stack from "@mui/material/Stack";

export default function PrivacyPolicy() {
  return (
    <Stack textAlign="justify" textJustify="auto">
      <PageTitle title="Privacy Policy" />
      <Typography>
        Protecting your private information is our priority. This Statement of
        Privacy applies to https://getgenre.com, and getGenre, LLC and governs
        data collection and usage. For the purposes of this Privacy Policy,
        unless otherwise noted, all references to getGenre, LLC include
        https://getgenre.com and getGenre. The getGenre website is a
        information site. By using the getGenre website, you consent to the
        data practices described in this statement.
      </Typography>
      <Typography variant="h5">
        Collection of your Personal Information
      </Typography>
      <Typography>
        getGenre may collect anonymous demographic information, which is not
        unique to you, such as your:
      </Typography>
      <Typography>
         -<span className="Apple-tab-span"> </span>Age
      </Typography>
      <Typography>
         -<span className="Apple-tab-span"> </span>Gender
      </Typography>
      <Typography>
         -<span className="Apple-tab-span"> </span>Location
      </Typography>
      <Typography>
         -<span className="Apple-tab-span"> </span>Language
      </Typography>
      <Typography>
         -<span className="Apple-tab-span"> </span>Interests
      </Typography>
      <Typography>
        We do not collect any personal information about you unless you
        voluntarily provide it to us. However, you may be required to provide
        certain personal information to us when you elect to use certain
        products or services. These may include: (a) registering for an
        account; (b) entering a sweepstakes or contest sponsored by us or one
        of our partners; (c) signing up for special offers from selected third
        parties; (d) sending us an email message; (e) submitting your credit
        card or other payment information when ordering and purchasing
        products and services. To wit, we will use your information for, but
        not limited to, communicating with you in relation to services and/or
        products you have requested from us. We also may gather additional
        personal or non-personal information in the future.
      </Typography>
      <Typography variant="h5">
        Sharing Information with Third Parties
      </Typography>
      <Typography>
        getGenre does not sell, rent or lease its customer lists to third
        parties.<span className="Apple-converted-space"> </span>
      </Typography>
      <Typography>
        getGenre may share data with trusted partners to help perform
        statistical analysis, send you email or postal mail, provide customer
        support, or arrange for deliveries. All such third parties are
        prohibited from using your personal information except to provide
        these services to getGenre, and they are required to maintain the
        confidentiality of your information.
      </Typography>
      <Typography>
        getGenre may disclose your personal information, without notice, if
        required to do so by law or in the good faith belief that such action
        is necessary to: (a) conform to the edicts of the law or comply with
        legal process served on getGenre or the site; (b) protect and defend
        the rights or property of getGenre; and/or (c) act under exigent
        circumstances to protect the personal safety of users of getGenre, or
        the public.<span className="Apple-converted-space"> </span>
      </Typography>
      <Typography variant="h5">Tracking User Behavior</Typography>
      <Typography>
        getGenre may keep track of the websites and pages our users visit
        within getGenre, in order to determine what getGenre services are the
        most popular. This data is used to deliver customized content and
        advertising within getGenre to customers whose behavior indicates that
        they are interested in a particular subject area.
      </Typography>
      <Typography variant="h5">
        Automatically Collected Information
      </Typography>
      <Typography>
        Information about your computer hardware and software may be
        automatically collected by getGenre. This information can include:
        your IP address, browser type, domain classNames, access times and
        referring website addresses. This information is used for the
        operation of the service, to maintain quality of the service, and to
        provide general statistics regarding use of the getGenre website.
      </Typography>
      <Typography variant="h5">Links</Typography>
      <Typography>
        This website contains links to other sites. Please be aware that we
        are not responsible for the content or privacy practices of such other
        sites. We encourage our users to be aware when they leave our site and
        to read the privacy statements of any other site that collects
        personally identifiable information.
      </Typography>
      <Typography variant="h5">
        Security of your Personal Information
      </Typography>
      <Typography>
        getGenre secures your personal information from unauthorized access,
        use, or disclosure. getGenre uses the following methods for this
        purpose:<span className="Apple-converted-space"> </span>
      </Typography>
      <Typography>
         -<span className="Apple-tab-span"> </span>SSL Protocol
      </Typography>
      <Typography>
        When personal information (such as a credit card number) is
        transmitted to other websites, it is protected through the use of
        encryption, such as the Secure Sockets Layer (SSL) protocol.
      </Typography>
      <Typography>
        We strive to take appropriate security measures to protect against
        unauthorized access to or alteration of your personal information.
        Unfortunately, no data transmission over the Internet or any wireless
        network can be guaranteed to be 100% secure. As a result, while we
        strive to protect your personal information, you acknowledge that: (a)
        there are security and privacy limitations inherent to the Internet
        which are beyond our control; and (b) security, integrity, and privacy
        of any and all information and data exchanged between you and us
        through this Site cannot be guaranteed.
      </Typography>
      <Typography variant="h5">Right to Deletion</Typography>
      <Typography>
        Subject to certain exceptions set out below, on receipt of a
        verifiable request from you, we will:
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Delete your personal
        information from our records; and
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Direct any service providers
        to delete your personal information from their records.
      </Typography>
      <Typography>
        Please note that we may not be able to comply with requests to delete
        your personal information if it is necessary to:
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Complete the transaction for
        which the personal information was collected, fulfill the terms of a
        written warranty or product recall conducted in accordance with
        federal law, provide a good or service requested by you, or reasonably
        anticipated within the context of our ongoing business relationship
        with you, or otherwise perform a contract between you and us;
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Detect security incidents,
        protect against malicious, deceptive, fraudulent, or illegal activity;
        or prosecute those responsible for that activity;
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Debug to identify and repair
        errors that impair existing intended functionality;
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Exercise free speech, ensure
        the right of another consumer to exercise his or her right of free
        speech, or exercise another right provided for by law;
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Comply with the California
        Electronic Communications Privacy Act;
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Engage in public or
        peer-reviewed scientific, historical, or statistical research in the
        public interest that adheres to all other applicable ethics and
        privacy laws, when our deletion of the information is likely to render
        impossible or seriously impair the achievement of such research,
        provided we have obtained your informed consent;
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Enable solely internal uses
        that are reasonably aligned with your expectations based on your
        relationship with us;<span className="Apple-converted-space"> </span>
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Comply with an existing
        legal obligation; or<span className="Apple-converted-space"> </span>
      </Typography>
      <Typography>
        •<span className="Apple-tab-span"> </span>Otherwise use your personal
        information, internally, in a lawful manner that is compatible with
        the context in which you provided the information.
      </Typography>
      <Typography variant="h5">Children Under Thirteen</Typography>
      <Typography>
        getGenre does not knowingly collect personally identifiable
        information from children under the age of thirteen. If you are under
        the age of thirteen, you must ask your parent or guardian for
        permission to use this website.
        <span className="Apple-converted-space"> </span>
      </Typography>
      <Typography variant="h5">External Data Storage Sites</Typography>
      <Typography>
        We may store your data on servers provided by third party hosting
        vendors with whom we have contracted.
      </Typography>
      <Typography variant="h5">Changes to this Statement</Typography>
      <Typography>
        getGenre reserves the right to change this Privacy Policy from time to
        time. We will notify you about significant changes in the way we treat
        personal information by sending a notice to the primary email address
        specified in your account, by placing a prominent notice on our
        website, and/or by updating any privacy information. Your continued
        use of the website and/or Services available after such modifications
        will constitute your: (a) acknowledgment of the modified Privacy
        Policy; and (b) agreement to abide and be bound by that Policy.
      </Typography>
      <Typography variant="h5">Contact Information</Typography>
      <Typography>
        getGenre welcomes your questions or comments regarding this Statement
        of Privacy. If you believe that getGenre has not adhered to this
        Statement, please contact getGenre at:
      </Typography>
      <Typography>
        getGenre, LLC<span className="Apple-converted-space"> </span>
      </Typography>
      <Typography>13359 N Highway 183 Ste 406 #2011</Typography>
      <Typography>
        Austin, Texas 78750<span className="Apple-converted-space"> </span>
      </Typography>
      <Typography>
        Email Address:<span className="Apple-converted-space"> </span>
      </Typography>
      <Typography>
        support@getgenre.com<span className="Apple-converted-space"> </span>
      </Typography>
      <Typography>Effective as of July 04, 2021</Typography>
    </Stack>
  );
}
