import { Typography } from "@mui/material";
import { fonts } from "../theme";

export default function Tagline(props) {
  return (
    (<Typography
      variant={props.variant}
      component={props.component}
      sx={{
        margin: "0",
        fontFamily: fonts.lightFont,
        letterSpacing: "0.15em",
        ...props.sx
      }}>THE <span style={{ fontFamily: fonts.boldFont }}>GENRE FINDER </span>FOR <span style={{ fontFamily: fonts.boldFont }}>MUSIC</span>
    </Typography>)
  );
}
