import { createContext, useState, useEffect, useCallback } from "react";
import Cookies from 'universal-cookie';
import getGenreAIURL, { getGenreAITokenURL, makeRequest, AppModal } from "../global";
import { useForm } from "react-hook-form";

const UserContext = createContext();
const cookies = new Cookies();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [fetchUser, setFetchUser] = useState(false);
  const [session, setSession] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(null);
  const [userError, setUserError] = useState(false);
  // bootstrapped essentially blocks the app from rendering until the UserContext is fully bootstrapped.
  // Three ways for the UserContext to be bootstrapped:
  // 1. The user is logged in successfully automatically.
  // 2. No session to restore upon load.
  // 3. Unable to successfully restore session upon load.
  const [bootstrapped, setBootstrapped] = useState(false);

  // Restores / refreshes the user's session.
  const login = useCallback(async ({secondsExpiresIn = null, rememberMe = false, forceRefresh = false} = {}) => {
    try {
      let thisSession = {};

      // Any new session data provided will overwrite the current session data.
      if (secondsExpiresIn !== undefined && secondsExpiresIn !== null) {
        const expires = secondsExpiresIn * 1000;
        // Ensures the session is refreshed before it expires.
        const expirationBuffer = 120 * 1000;

        thisSession.expires = Date.now() + expires - expirationBuffer;
        thisSession.rememberMe = rememberMe;

        let cookieExpires;

        // Set an expiration time for the cookies to ensure sessions are persisted across browser restarts.
        if (thisSession.rememberMe) {
          // getGenre AI refresh tokens are valid for 1 year, so the session cookie should be valid for 1 year.
          cookieExpires = new Date(Date.now() + 31536000000 - expirationBuffer);
        } else {
          // Rmeove the session cookie when the access token expires.
          cookieExpires = new Date(thisSession.expires);
        }
    
        cookies.set("session",
          JSON.stringify(thisSession),
          {
            path: "/",
            expires: cookieExpires
          }
        );

        const sessionCookie = cookies.get("session");

        if (sessionCookie === undefined || sessionCookie === null) {
          // TODO what about if the user agrees to taking such risks, seems like we could then allow them to log in without cookies enabled,
          // and if sessions are screwy or get highjacked, then that's on them.
          // Although it is possible to set the access_token and refresh_tokens in memory as well
          // and just always send them with the request, which would then allow a user to set up
          // a new session on a new tab without affecting the other current session, we strictly
          // rely on the server's httpOnly token cookies to ensure the session is absolutely secure
          // from any client-side manipulation (e.g. XSS attacks, etc.).
          throw new Error("Cookies must be enabled to log in.");
        }
      } else {
        // Always use the cookie to get the current session in order to determine if the session has changed, such as when
        // the user logs in on another tab.
        const sessionCookie = cookies.get("session");

        if (sessionCookie !== undefined && sessionCookie !== null) {
          if (sessionCookie.expires === undefined || sessionCookie.expires === null) {
            throw new Error("Session cookie must have an expiration to restore the session. Please log in again.");
          } else {
            thisSession.expires = Number(sessionCookie.expires);
          }

          if (sessionCookie.rememberMe !== undefined && sessionCookie.rememberMe !== null) {
            thisSession.rememberMe = sessionCookie.rememberMe;
          } else {
            thisSession.rememberMe = false;
          }
        } else if (session) {
          // If the session cookie is missing, but a session exists in memory, then the user either manually deleted the cookie or
          // doesn't have cookies enabled, or the cookie naturally expired indicating the session expired.
          // Thus, if users have cookies disabled, they will be logged out prematurely.
          throw new Error("Session cookie not found or expired.");
        }

        if (session && session.expires !== thisSession.expires) {
          // This can happen when the user logs in on another tab or the session cookie was manually manipulated.
          throw new Error("Session data has changed unexpectedly.");
        }
      }

      if (Object.keys(thisSession).length === 0) {
        // No session, so ensure we're done bootstrapping the UserContext.
        setBootstrapped(true);
      } else {
        // Important to check even stored session data since the user could be manipulating the cookies.
        if (!Number.isInteger(thisSession.expires)) {
          throw new Error("Session expiration time must be an integer. Got: " + thisSession.expires);
        }

        if (typeof thisSession.rememberMe !== "boolean") {
          throw new Error("Session rememberMe must be a boolean. Got: " + thisSession.rememberMe);
        }

        // Only need to set the session upon instantiating or refreshing a session, or else
        // components will re-render unnecessarily due to dependencies on the UserContext.
        if (forceRefresh || !session || session.expires !== thisSession.expires) {
          setSession(thisSession);
        }
      }
    } catch (error) {
      // Clear all session data to ensure any previous, and possibly erroneous, session data is removed.
      // Using state function to avoid the added useEffect dependency.
      setSessionExpired(true);
      // Let calling functions log and send any metrics/alerts since the user could be manipulating cookies and
      // causing unnecessary alerts.
      throw error;
    }
  }, [session]);

  const handleRequest = useCallback(async (fn, retry = true, ...args) => {
    // This should not be called if the user session is not valid.
    const requestHelper = async (fn, ...args) => {
      try {
        return await fn(...args);
      } catch (error) {
        // Let the caller handle the expected responses.
        if (error) {
          if (error.response) {
            const error_message = "Failed to make user request due to the following error: " + error;

            if (error.response.status === 401) {
              throw new Error(error_message);
            } else if (error.response.status >= 500) {
              // Not throwing the error to prevent removing the session unecessarily due to an unexpected network error.
              // TODO send metrics/alerts since the API is down or misbehaving.
              // Don't want to log errors for 4xx responses since they are typically due to user input errors.
              console.error(error_message);
            }
          }
        }

        return error;
      }
    }

    try {
      if (!session) {
        throw new Error("No session data found to make user request.");
      }

      const refresh = session.rememberMe;

      try {
        if (session.expires <= Date.now()) {
          throw new Error("Access token has expired.");
        } else {
          return await requestHelper(fn, ...args);
        }
      } catch (error) {
        if (!refresh) {
          throw error;
        } else {
          try {
            const result = await makeRequest(
              getGenreAITokenURL,
              "POST",
              {
                grant_type: 'refresh_token',
              },
              {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              true
            );

            // Update the session.
            await login({secondsExpiresIn: result.data.expires_in, rememberMe: true});
          } catch (error) {
            // Don't send metrics/alerts since the user could be manipulating the session cookie and causing unnecessary alerts.
            throw new Error("Failed to refresh user session due to the following error: " + error);
          }

          if (retry) {
            // Although the session state may have not been updated yet, the token cookies have been refreshed and persisted,
            // so the request can be retried.
            return await requestHelper(fn, ...args);
          }
        }
      }
    } catch (error) {
      // No need to send metrics/alerts since the user might be manipulating the cookies and causing unnecessary alerts, or there is no session to begin with.
      console.error(error.message);

      // If unable to obtain the session, the access token is expired, or unable to refresh, remove the session data.
      // Can't call logout here since it requires a valid session to make the request to the API. Any HTTPOnly cookies will remain until they naturally expire or get replaced.
      setSessionExpired(true);
    }
  }, [login, session]);

  const removeSessionData = useCallback(() => {
    cookies.remove("session", { path: "/" });
    setSession(null);
    setSessionExpired(null);
    // In case any error during bootstrapping.
    setBootstrapped(true);
  }, []);

  const logout = useCallback(async (logoutWithAPI = true) => {
    if (logoutWithAPI) {
      await handleRequest(async () => {
        await makeRequest(getGenreAIURL + "/logout", "POST", null, null, true);
      })
    }

    // Doing here instead of in the handleRequest function to ensure the user is always logged out even if the request fails. 
    removeSessionData();
    console.log("You are now logged out.");
  }, [handleRequest, removeSessionData]);

  useEffect(() => {
    if (loggedIn === true) {
      console.log("You are now logged in.")
      setBootstrapped(true);
    } else {
      setUser(null);
    }
  }, [loggedIn])

  useEffect(() => {
    if (user && session) {
      // Important to not set loggedIn to true until the user is set to ensure the session is valid and
      // the context is fully bootstrapped.
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user, session]);

  useEffect(() => {
    // If we're not able to get the user and we're not logged in, remove any session data in order to not
    // accidentally log the user back in when the server comes back online--security issue if the user
    // appears to be logged out but then their acccount is logged back in when the server comes online.
    // Can't call setSessionExpired directly from where setUserError is called because if the user is
    // already logged in, we don't want to remove their session--only if they reload will it be removed
    // since they then won't be logged in and the session will fail to bootstrap due to a given error.
    // Also don't want loggedIn as dependency on handleRequest() to avoid unnecessary re-renders elsewhere.
    if (userError && !loggedIn) {
      // Safe to expire the session since the user is not logged in yet.
      setSessionExpired(true);
    }
  }, [userError, loggedIn]);

  // An effect for handling a session's natural or error-induced expiration.
  useEffect(() => {
    // An existing session has expired.
    if (sessionExpired) {
      if (loggedIn) {
        console.log("Your session has expired. Please reload the page to log in again.");
        setOpenSessionExpiredModal(true);
      } else {
        // The session, while bootstrapping or manually logging in, ran into an issue.
        // No need to notify the user since they're already aware they're not logged in.
        // Thus, we need to reset all session flags to ensure the user can try to log in again.
        removeSessionData();
      }
    }
  }, [sessionExpired, loggedIn, removeSessionData]);

  useEffect(() => {
    const bootstrapSession = () => {
      if (document.visibilityState === 'visible') {
        (async () => {
          try {
            await login();
          } catch (error) {
            // Don't send metric/alert since the user could be manipulating the cookies and causing unnecessary alerts.
            console.error("Failed to load previous session due to the following error: " + error);
          }
        })();
      }
    }

    // Always validate the session when the app becomes visible.
    document.addEventListener('visibilitychange', bootstrapSession);

    if (!session) {
      // In dev/strict mode, user fetching can be done twice, thus possibly conflicting with
      // each other when trying to refresh the session. Thereofore, each concurrent run of this
      // effect will set the fetchUser state to true, which will trigger the user fetching effect to
      // run only once since effects are only run when their dependencies change.
      if (!fetchUser) {
        setFetchUser(true);
      } else {
        bootstrapSession();
      }
    }

    return () => {
      document.removeEventListener('visibilitychange', bootstrapSession);
    }
  }, [login, session, fetchUser]);

  useEffect(() => {
    const updateUserInfo = async () => {
      // Don't retry the request in handleRequest if say the access token is expired since when refreshing, the user info will be fetched again and thus retrying this initial request after a 
      // successful refresh would cause the request to ultimately be made twice.
      await handleRequest(async () => {
        try {
          const result = await makeRequest(getGenreAIURL + "/userinfo", "GET", null, null, true);

          if (result.data) {
            setUser(result.data);
          } else {
            // This should never happen.
            // TODO send metrics/alerts since the API is down or misbehaving.
            throw new Error("No user data in response.")
          }
        } catch (error) {
          console.error("Failed to fetch user info due to the following error: " + error);
          setUserError(true);
          throw error
        }
      }, false);
    };

    if (session && !sessionExpired) {
      (async () => {
        await updateUserInfo();
      })();
    }
  }, [handleRequest, session, sessionExpired]);

  return (
    <UserContext.Provider value={{
      logout: logout,
      login: login,
      user: user,
      loggedIn: loggedIn,
      handleRequest: handleRequest,
      bootstrapped: bootstrapped
    }}>
      {children}
      <AppModal
        name="session-expired"
        open={openSessionExpiredModal}
        prompt={
          {
            title: "Session Expired",
            message: "Your session has expired. Reload the page to log in again.",
          }
        }
        formProps={{
          methods: useForm(),
          onSubmit: () => {
            // Need to reload to ensure the latest updates and session data are fetched, such as when a new user logs in on another tab.
            // We can't remove any session cookies before doing so since it might have been the user who logged in on another tab; thus,
            // if the access token or refresh token is still invalid, the user will be logged out on the next reload when trying to bootstrap the user.
            window.location.reload();
          },
          submitButtonProperties: {
            name: "Reload",
          }
        }}
      />
    </UserContext.Provider>
  );
}

export { UserContext, UserContextProvider };