import { useEffect, useState, useContext, useMemo, useCallback } from "react";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import PageTitle from "../components/PageTitle";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import Form, { onSubmit as formOnSubmit, processInputResponseErrors, defaultSubmitButtonProperties } from "../components/Form";
import { getGenreAIURL, yupUsernameValidations, yupPasswordValidations, yupEmailValidations, makeRequest, AppModal } from "../global";
import * as Yup from 'yup';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@mui/icons-material/Done';
import SyncIcon from '@mui/icons-material/Sync';
import { UserContext } from "../components/UserContext";
import Snackbar from "../components/Snackbar";

const clearSessionsKey = "clear_sessions";
const cancelAccountKey = "cancel_account";

const newEmailValidationSchemaShape = {
  'email': yupEmailValidations
};

const newPasswordValidationSchemaShape = {
  'password': yupPasswordValidations
};

const newUsernameValidationSchemaShape = {
  'username': yupUsernameValidations
};

const confirmPasswordValidationSchemaShape = {
  'current_password': Yup.string()
    .required('Your current password is required.'),
};

const newEmailInputFieldNamesAndProperties = {
  'email': {
    'type': 'email',
    'autocomplete': 'email',
    'label': 'New Email'
  },
}

const newPasswordInputFieldNamesAndProperties = {
  'password': {
    'type': 'password',
    'autocomplete': 'new-password',
    'label': 'New Password'
  },
}

const newUsernameInputFieldNamesAndProperties = {
  'username': {
    'type': 'text',
    'autocomplete': 'username',
    'label': 'New Username',
    'sx': {
      marginBottom: "16px"
    }
  },
}

const clearAccountSessionsInputFieldNamesAndProperties = {};

const cancelAccountInputFieldNamesAndProperties = {};

const confirmPasswordInputFieldNamesAndProperties = {
  'current_email': {
    'type': 'email',
    'disabled': true,
    'display': 'none',
    'autocomplete': 'off',
    'inputProps': {
      'readOnly': true,
    },
  },
  'current_password': {
    'type': 'password',
    'autocomplete': 'current-password',
    'label': 'Current Password',
  },
}

const confirmPasswordSubmitButtonProperties = {
  'name': 'confirm'
};

const defaultConfirmPasswordPrompt = {
  'title': 'Confirm Account Update',
  'message': 'Enter your current password to confirm your account update.',
};

const newEmailValidationSchema = Yup.object().shape(newEmailValidationSchemaShape);
const newPasswordValidationSchema = Yup.object().shape(newPasswordValidationSchemaShape);
const newUsernameValidationSchema = Yup.object().shape(newUsernameValidationSchemaShape);
const confirmPasswordValidationSchema = Yup.object().shape(confirmPasswordValidationSchemaShape);

const accountSettingsUpdatedMessage = "Account settings updated.";
const emailVerificationSuccessMessage = "Verification email sent.";
const errorMessage = "Failed to process your request.";

export default function Account() {
  const [editEmail, setEditEmail] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [editUsername, setEditUsername] = useState(false);
  const [userSettings, setUserSettings] = useState({});
  const [cancelAccount, setCancelAccount] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [confirmPasswordData, setConfirmPasswordData] = useState(null);
  const [confirmPasswordPrompt, setConfirmPasswordPrompt] = useState(defaultConfirmPasswordPrompt);
  const [logoutAfterUpdate, setLogoutAfterUpdate] = useState(null);
  const [successfulUpdate, setSuccessfulUpdate] = useState(null);
  const [copyTitle, setCopyTitle] = useState("Copy");
  const userContext = useContext(UserContext);
  const [successMessage, setSuccessMessage] = useState(accountSettingsUpdatedMessage);

  const resendVerificationEmail = useCallback(async () => {
    await userContext.handleRequest(async () => {
      const url = `${getGenreAIURL}/accounts/resend_verification_email`;

      try {
        await makeRequest(url, "POST", null, null, true);
        setSuccessMessage(emailVerificationSuccessMessage);
        setSuccessfulUpdate(true);
      } catch (error) {
        setSuccessfulUpdate(false);
        throw error;
      }
    });
  }, [userContext]);

  const displayedEmailInputFieldNamesAndProperties = useMemo(() => {
    return {
      'displayed_email': {
        'type': 'email',
        'disabled': true,
        'autocomplete': 'off',
        'inputProps': {
          'readOnly': true,
        },
        'label': (userContext.user.email_verified && 'Email') || (<>Email (<Link onClick={resendVerificationEmail} >pending verification</Link>)</>),
      },
    }
  }, [userContext.user.email_verified, resendVerificationEmail]);

  const displayedPasswordInputFieldNamesAndProperties = useMemo(() => {
    return {
      'displayed_password': {
        'type': 'password',
        'disabled': true,
        'adornment': false,
        'autocomplete': 'off',
        'inputProps': {
          'readOnly': true,
        },
        'label': "Password",
      },
    }
  }, []);

  const displayedUsernameInputFieldNamesAndProperties = useMemo(() => {
    return {
      'displayed_username': {
        'type': 'text',
        'disabled': true,
        'autocomplete': 'off',
        'inputProps': {
          'readOnly': true,
        },
        'sx': {
          marginBottom: "16px"
        },
        'label': "Username",
      },
    }
  }, []);

  const displayedEmailDefaultValues = useMemo(() => {
    return {
      displayed_email: userContext.user.email,
    }
  }, [userContext.user.email]);

  const displayedUsernameDefaultValues = useMemo(() => {
    return {
      displayed_username: userContext.user.username,
    }
  }, [userContext.user.username]);

  const confirmPasswordDefaultValues = useMemo(() => {
    return {
      current_email: userContext.user.email,
      current_password: '',
    }
  }, [userContext]);

  const displayedPasswordDefaultValues = {
    displayed_password: '************',
  };

  const clearAccountSessionsDefaultValues = {
    [clearSessionsKey]: 'true',
  }

  const cancelAccountDefaultValues = {
    [cancelAccountKey]: 'true',
  }

  const displayedEmailMethods = useForm({
    defaultValues: displayedEmailDefaultValues,
  });

  const displayedPasswordMethods = useForm({
    defaultValues: displayedPasswordDefaultValues,
  });

  const displayedUsernameMethods = useForm({
    defaultValues: displayedUsernameDefaultValues,
  });

  const cancelAccountMethods = useForm({
    defaultValues: cancelAccountDefaultValues,
  });

  const clearAccountSessionsMethods = useForm({
    defaultValues: clearAccountSessionsDefaultValues,
  });

  const newEmailMethods = useForm({
    resolver: yupResolver(newEmailValidationSchema),
  });

  const newPasswordMethods = useForm({
    resolver: yupResolver(newPasswordValidationSchema),
  });

  const newUsernameMethods = useForm({
    resolver: yupResolver(newUsernameValidationSchema),
  });

  const confirmPasswordMethods = useForm({
    defaultValues: confirmPasswordDefaultValues,
    resolver: yupResolver(confirmPasswordValidationSchema),
  });

  const closeAndClearConfirmPassword = useCallback(() => {
    setConfirmPassword(false);
    setConfirmPasswordPrompt(defaultConfirmPasswordPrompt);
    setConfirmPasswordData(null);
    confirmPasswordMethods.reset(confirmPasswordDefaultValues);
  }, [confirmPasswordDefaultValues, confirmPasswordMethods]);

  const onSuccess = async () => {
    if (confirmPasswordData.setEditState) {
      confirmPasswordData.setEditState(false);
    }

    setSuccessMessage(accountSettingsUpdatedMessage);
    setSuccessfulUpdate(true);

    if (logoutAfterUpdate) {
      // No need to logout with the API endpoint if the user has already cleared sessions or cancelled their account.
      await userContext.logout(false);
    } else {
      try {
        // Refresh user session and info to reflect changes.
        await userContext.login({forceRefresh: true});
        console.log("Account updated successfully.");
      } catch (error) {
        // TODO send custom metric/alert since this should never happen and indicates a bug.
        console.error("Failed to refresh session after account update.", error);
      }
    }
  };

  const onError = (response, setError, inputFieldNamesAndProperties, submitButtonProperties) => {
    setSuccessfulUpdate(false);

    // Display any auth errors in the confirm password modal.
    if (!response || !response.status || (response.status >= 400 && response.status !== 422)) {
      processInputResponseErrors(response, confirmPasswordInputFieldNamesAndProperties, confirmPasswordSubmitButtonProperties, confirmPasswordMethods.setError);
    } else {
      processInputResponseErrors(response, inputFieldNamesAndProperties, submitButtonProperties, setError);
    }
  };

  const confirmPasswordOnSubmit = async (formValues, e) => {
    const url = `${getGenreAIURL}/accounts/edit`;
    formValues = {...confirmPasswordData.formValues, ...formValues}
    await formOnSubmit(formValues, confirmPasswordData.e, confirmPasswordData.inputFieldNamesAndProperties, confirmPasswordData.submitButtonProperties, url, confirmPasswordData.methods.setError, onSuccess, onError, null, true, userContext)
  };

  const onSubmit = (formValues, e, methods, inputFieldNamesAndProperties, submitButtonProperties, modalConfirmPrompt, setEditState) => {
    if (formValues[clearSessionsKey] || formValues[cancelAccountKey]) {
      setLogoutAfterUpdate(true);
    }

    setConfirmPasswordData(
      {
        formValues: formValues,
        e: e,
        methods: methods,
        inputFieldNamesAndProperties: inputFieldNamesAndProperties,
        submitButtonProperties: submitButtonProperties,
        setEditState: setEditState
      }
    );

    if (modalConfirmPrompt) {
      setConfirmPasswordPrompt(modalConfirmPrompt);
    };

    setConfirmPassword(true);
  }

  /* TODO this can be done better... maybe with a single dict param that specifies all that's needed for the given edit/copy/rotate button. */
  const getEditIcons = useCallback((methods, inputFieldNamesAndProperties, submitButtonProperties, editState=null, setEditState=null, editable=false, copyable=false, rotatable=false) => {
    let inputToCopy = "";

    if (inputFieldNamesAndProperties) {
      inputToCopy = methods.getValues()[Object.keys(inputFieldNamesAndProperties)[0]];
    }

    return (
      /* 100px width to have a placeholder for submit action */
      /* TODO calculate marginTop dynamically? */
      /* 11px marginTop to account for label heading on TextFields and paddingTop on IconButton, which allows the icons to line up with the input properly. */
      (<Stack
        direction="row"
        sx={{
          width: "100px",
          alignSelf: "flex-start",
          marginTop: "11px"
        }}>
        {editable &&
          <Stack direction="row">
            {(!editState &&
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => setEditState(!editState)}
                  aria-label="edit"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>) ||
              <Tooltip title="Cancel edit">
                <IconButton
                  onClick={() => setEditState(!editState)}
                  aria-label="cancel edit"
                >
                  <EditOffIcon />
                </IconButton>
              </Tooltip>
            }
            {editState &&
              <Tooltip title="Submit">
                <IconButton
                  onClick={() => {
                    methods.handleSubmit((formValues, e) => onSubmit(formValues, e, methods, inputFieldNamesAndProperties, submitButtonProperties, null, setEditState))();
                  }}
                  aria-label="Submit"
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
            }
          </Stack>
        }
        {copyable &&
          <Tooltip title={copyTitle}>
            <IconButton
              onClick={() => {
                // Note, this will fail if testing with an insecure origin (i.e. http://local.deve)
                navigator.clipboard.writeText(inputToCopy);
                setCopyTitle("Copied!");
              }}
              onMouseOut={() => {
                setCopyTitle("Copy");
              }}
              aria-label="Copy"
            >
              <ContentCopyIcon/>
            </IconButton>
          </Tooltip>
        }
        {rotatable &&
          <Tooltip title="Rotate">
            <IconButton
              onClick={() => {
                methods.handleSubmit((formValues, e) => onSubmit(formValues, e, methods, inputFieldNamesAndProperties, submitButtonProperties, null, setEditState))();
              }}
              aria-label="Rotate"
            >
              <SyncIcon/>
            </IconButton>
          </Tooltip>
        }
      </Stack>)
    );
  }, [copyTitle]);

  const getAccountSetting = useCallback((methods, inputFieldNamesAndProperties, setEditState, editIcons, submitButtonProperties, key, confirmPasswordPrompt) => {
    key = key || Object.keys(inputFieldNamesAndProperties)[0];

    if (!submitButtonProperties) {
      submitButtonProperties = {
        ...defaultSubmitButtonProperties,
        'sx': {
          'display': 'none',
        }
      };
    }

    // For now, just use the input name as the key for the Stack.
    // minHeight to ensure the Stack is at least as tall as the input field so when edit mode is toggled, the Stack doesn't shrink and cause the input fields to move.
    return (
      (<Stack
        key={key}
        direction="row"
        sx={{
          alignItems: "center",
          width: "100%",
          maxWidth: "350px",
          minHeight: "65px",
        }}>
        <FormProvider {...methods}>
          <Form
            onSubmit={(formValues, e) => onSubmit(formValues, e, methods, inputFieldNamesAndProperties, submitButtonProperties, confirmPasswordPrompt, setEditState)}
            submitButtonProperties={submitButtonProperties}
            inputFieldNamesAndProperties={inputFieldNamesAndProperties}
          />
        </FormProvider>
        {editIcons}
      </Stack>)
    );
  }, []);

  // Reset forms when default values change, such as when an account is updated.
  useEffect(() => {
    displayedEmailMethods.reset(displayedEmailDefaultValues);
    displayedUsernameMethods.reset(displayedUsernameDefaultValues);
    confirmPasswordMethods.reset(confirmPasswordDefaultValues);
  }, [
    displayedEmailMethods,
    displayedUsernameMethods,
    displayedEmailDefaultValues,
    displayedUsernameDefaultValues,
    confirmPasswordMethods,
    confirmPasswordDefaultValues
  ]);

  useEffect(() => {
    if (confirmPasswordMethods.formState.isSubmitSuccessful) {
      closeAndClearConfirmPassword();
    }
  }, [confirmPasswordMethods.formState.isSubmitSuccessful, closeAndClearConfirmPassword])

  useEffect(() => {
    const settings = [];

    const clearAccountSessionsConfirmPasswordPrompt = {
      'title': 'Confirm Global Account Logout',
      'message': 'Enter your current password to log out of all devices.',
    };

    const cancelAccountConfirmPasswordPrompt = {
      'title': 'Confirm Account Cancellation',
      'message': 'Enter your current password to cancel your account.',
      'subPrompt': (
        <Typography id="modal-warning" sx={{ textTransform: 'uppercase', mt: 2, color: 'red', fontWeight: 'bold', marginBottom: "15px" }}>
          Cancelling your account can not be undone!
        </Typography>
      ),
    };

    const clearAccountSessionsSubmitButtonProperties = {
      'name': 'Logout all devices',
      'variant': 'text',
      'color': 'secondary',
      'sx': {
        marginTop: '30px',
      },
    };

    settings.push(getAccountSetting(clearAccountSessionsMethods, clearAccountSessionsInputFieldNamesAndProperties, null, null, clearAccountSessionsSubmitButtonProperties, 'logout', clearAccountSessionsConfirmPasswordPrompt));

    const cancelAccountSubmitButtonProperties = {
      'name': 'Cancel my account',
      'variant': 'text',
      'color': 'primary',
    };

    settings.push(getAccountSetting(cancelAccountMethods, cancelAccountInputFieldNamesAndProperties, null, null, cancelAccountSubmitButtonProperties, 'cancel', cancelAccountConfirmPasswordPrompt));

    setCancelAccount(settings);
  }, [
    getAccountSetting,
    getEditIcons,
    clearAccountSessionsMethods,
    cancelAccountMethods,
  ]);

  useEffect(() => {
    const editEmailIcons = getEditIcons(newEmailMethods, newEmailInputFieldNamesAndProperties, defaultSubmitButtonProperties, editEmail, setEditEmail, true);
    let setting;

    if (editEmail) {
      setting = getAccountSetting(newEmailMethods, newEmailInputFieldNamesAndProperties, setEditEmail, editEmailIcons);
    } else {
      setting = getAccountSetting(displayedEmailMethods, displayedEmailInputFieldNamesAndProperties, setEditEmail, editEmailIcons);
      newEmailMethods.reset();
    }

    setUserSettings(u => {
      u = {...u};
      u['email'] = setting;
      return u;
    });
  }, [
    getAccountSetting,
    getEditIcons,
    displayedEmailInputFieldNamesAndProperties,
    editEmail,
    displayedEmailMethods,
    newEmailMethods,
  ]);

  useEffect(() => {
    const editPasswordIcons = getEditIcons(newPasswordMethods, newPasswordInputFieldNamesAndProperties, defaultSubmitButtonProperties, editPassword, setEditPassword, true);
    let setting;

    if (editPassword) {
      setting = getAccountSetting(newPasswordMethods, newPasswordInputFieldNamesAndProperties, setEditPassword, editPasswordIcons);
    } else {
      setting = getAccountSetting(displayedPasswordMethods, displayedPasswordInputFieldNamesAndProperties, setEditPassword, editPasswordIcons);
      newPasswordMethods.reset();
    }

    setUserSettings(u => {
      u = {...u};
      u['password'] = setting;
      return u;
    });
  }, [
    getAccountSetting,
    getEditIcons,
    displayedPasswordInputFieldNamesAndProperties,
    editPassword,
    displayedPasswordMethods,
    newPasswordMethods,
  ]);

  useEffect(() => {
    const editUsernameIcons = getEditIcons(newUsernameMethods, newUsernameInputFieldNamesAndProperties, defaultSubmitButtonProperties, editUsername, setEditUsername);
    let setting;

    if (editUsername) {
      setting = getAccountSetting(newUsernameMethods, newUsernameInputFieldNamesAndProperties, setEditUsername, editUsernameIcons);
    } else {
      setting = getAccountSetting(displayedUsernameMethods, displayedUsernameInputFieldNamesAndProperties, setEditUsername, editUsernameIcons);
      newUsernameMethods.reset();
    }

    setUserSettings(u => {
      u = {...u};
      u['username'] = setting;
      return u;
    });
  }, [
    getAccountSetting,
    getEditIcons,
    displayedUsernameInputFieldNamesAndProperties,
    editUsername,
    displayedUsernameMethods,
    newUsernameMethods,
  ]);

  return (<>
    <PageTitle title="My Account" />
    <Typography variant="h5">
      User Settings
    </Typography>
    <Typography sx={{marginBottom: "30px"}}>
      Click on the "<EditIcon fontSize="small" sx={{padding: "2px", verticalAlign: "middle"}} />" icon next to each setting (when available) to update your account details.
    </Typography>
    <Stack alignItems="center">
      {Object.values(userSettings)}
      {cancelAccount}
    </Stack>
    <AppModal
      name="confirm-password"
      open={confirmPassword}
      onClose={closeAndClearConfirmPassword}
      prompt={confirmPasswordPrompt}
      formProps={{
        methods: confirmPasswordMethods,
        onSubmit: confirmPasswordOnSubmit,
        inputFieldNamesAndProperties: confirmPasswordInputFieldNamesAndProperties,
        submitButtonProperties: confirmPasswordSubmitButtonProperties
      }}
    />
    <Snackbar successfulUpdate={successfulUpdate} setSuccessfulUpdate={setSuccessfulUpdate} successMessage={successMessage} errorMessage={errorMessage} />
  </>);
}
