import Backdrop from '@mui/material/Backdrop';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Stack from "@mui/material/Stack";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { FormProvider } from "react-hook-form";
import Form from "./components/Form";
import Typography from "@mui/material/Typography";

export const spotifyManagerURL=`https://getgenre.com:6443`;
export const getGenreAIURL=`https://api.getgenre.com`;
export const getGenreAITokenURL=`${getGenreAIURL}/token`;
export const supportEmail="support@getgenre.com";
export const undefinedArtist = 'undefinedartist';
export const defaultErrorMessage = "An error occurred. Please try again later.";

export const yupEmailValidations = Yup.string()
  .required('Email is required.')
  .email('Email is invalid.')

export const yupUsernameValidations = Yup.string()
  .required('Username is required.')
  .matches(
    '^[a-zA-Z0-9][a-zA-Z0-9_-]*[a-zA-Z0-9]$',
    'Usernames can only contain alphanumeric characters, underscores, hyphens, and must start and end with an alphanumeric character.'
  )
  .min(6, 'Username must be at least 6 characters.')
  .max(20, 'Username must not exceed 20 characters.')

export const yupPasswordValidations = Yup.string()
  .required('Password is required.')
  .min(8, 'Password must be at least 8 characters.')
  .max(40, 'Password must not exceed 40 characters.')
  .matches(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])',
    'Password must contain lower case (a-z), upper case (A-Z) and numbers (0-9).'
  )

export const makeRequest = async (url, method, data, headers, withCredentials, signal) => {
  return await axios(
    url,
    {
      method: method || 'GET',
      data: data,
      timeout: 10000,
      headers: headers,
      withCredentials: withCredentials,
      signal: signal
    }
  );
}

export const getArtistAlbumURI = (artistName, artistID, albumName, albumID) => {
  // URLs won't work without some type of artist.
  if (!artistName) {
    artistName = undefinedArtist;
  }

  const artistEncoded = encodeURIComponent(artistName);

  let uri = `/artist/${artistEncoded}`;

  if (albumName) {
    const albumEncoded = encodeURIComponent(albumName);
    uri = `${uri}/album/${albumEncoded}`;
  }

  if (artistID) {
    uri = `${uri}?artistID=${artistID}`;

    if (albumID) {
      uri = `${uri}&albumID=${albumID}`;
    }
  } else if (albumID) {
    uri = `${uri}?albumID=${albumID}`;
  }

  return uri;
};

export const getGenreURI = (genre) => {
  const genreEncoded = encodeURIComponent(genre.toLowerCase());

  return `/genre/${genreEncoded}`;
};

// https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
export const toTitleCase = (str) => {
  if (str) {
    const articles = ['a', 'an', 'the'];
    const conjunctions = ['for', 'and', 'nor', 'but', 'or', 'yet', 'so'];
    const prepositions = [
      'with', 'at', 'from', 'into','upon', 'of', 'to', 'in', 'for',
      'on', 'by', 'like', 'over', 'plus', 'but', 'up', 'down', 'off', 'near'
    ];

    return str.replace(
      /\w\S*/g,
      function(txt) {
        if (!(articles.includes(txt) || conjunctions.includes(txt) || prepositions.includes(txt))) {
          return txt.charAt(0).toUpperCase() + txt.substr(1);
        } else {
          return txt;
        }
      }
    );
  }
}

export const AppBackdrop = (props) => {
  return (
    <Backdrop
      sx={{
        backgroundColor: props.backgroundColor || "rgb(0,0,0,.5)",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={props.open}
    >
      <CircularProgress />
    </Backdrop>
  )
}

export const AppModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby={`modal-${props.name}`}
      aria-describedby={`modal-${props.name}-description`}
      aria-modal="true"
    >
      <Stack
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#3a3a3a",
          boxShadow: 24,
          p: 4,
          outline: "none",
          padding: "15px",
          paddingBottom: "30px",
          paddingLeft: "30px",
          paddingRight: "30px",
          maxHeight: "95vh",
          minWidth: "250px",
          overflow: "auto",
        }}
      >
        <Stack
          align="center"
          sx={{
            margin: "auto",
            alignItems: "center"
          }}>
          {props.onClose &&
            <Stack
              sx={{
                flexDirection: "row-reverse",
                width: "100%"
              }}>
              <Tooltip title="Close">
                <IconButton
                  aria-label="Close"
                  onClick={props.onClose}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          }
          <Stack>
            <Typography id={`modal-${props.name}-title`} variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
              {props.prompt.title}
            </Typography>
            <Typography id={`modal-${props.name}-message`} sx={{ mt: 2, marginBottom: "15px" }}>
              {props.prompt.message}
            </Typography>
            {props.prompt.subPrompt}
          </Stack>
          <FormProvider {...props.formProps.methods}>
            <Form
              onSubmit={props.formProps.onSubmit}
              inputFieldNamesAndProperties={props.formProps.inputFieldNamesAndProperties}
              submitButtonProperties={props.formProps.submitButtonProperties}
            />
          </FormProvider>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default getGenreAIURL;
