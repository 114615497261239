import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { colors } from "../theme";
import { toTitleCase } from "../global";
import Divider from '@mui/material/Divider';

export default function PageTitle(props) {
  const [ pageTitle, setPageTitle ] = useState(null);
  const [ pageSubtitle, setPageSubtitle ] = useState(null);
  const [ pageDescription, setPageDescription ] = useState(null);
  const [ pageTitleHref, setPageTitleHref ] = useState(null);

  useEffect(() => {
    if (props.titleHref) {
      setPageTitleHref(props.titleHref);
    }

    let formattedTitle = props.title;

    if (!props.preserveTitleCase) {
      formattedTitle = toTitleCase(props.title);
    }

    setPageTitle(formattedTitle);

    let title = formattedTitle;

    if (props.subtitle) {
      let formattedSubtitle = props.subtitle;

      if (!props.preserveSubtitleCase) {
        formattedSubtitle = toTitleCase(props.subtitle);
      }

      setPageSubtitle(formattedSubtitle);

      title = `${formattedTitle} - ${formattedSubtitle}`;
    }

    if (props.description) {
      let formattedDescription = props.description;

      if (!props.preserveDescriptionCase) {
        formattedDescription = toTitleCase(props.description);
      }

      setPageDescription(formattedDescription);

      title = `${title} | ${formattedDescription}`;
    }

    document.title = `${title} - getGenre()`;
  }, [props]);

  return (
    (<Stack
      align="center"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "15px"
      }}>
      <Stack>
        <Typography variant="h4" component="h1" sx={{marginBottom: "0", fontWeight: "bold"}}>
          {(pageTitleHref &&
            <Link href={pageTitleHref} color={colors.white}>
              {pageTitle}
            </Link>) ||
            pageTitle
          }
        </Typography>
        {pageSubtitle &&
          <Typography variant="h5" component="h2" sx={{marginBottom: "0"}}>{pageSubtitle}</Typography>
        }
        <Divider variant="middle" sx={{margin: "10px 0"}} />
      </Stack>
      {pageDescription &&
        <Stack>
          <Typography variant="h6" component="h3">{pageDescription}</Typography>
        </Stack>
      }
    </Stack>)
  );
}
